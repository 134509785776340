<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ labelPage }} Reimburse</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-reimburse">Reimburse </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelPage }}
                  </li>
                </ol>
              </nav>
            </div>
            <div
              class="button-edit"
              v-show="validated == 1 && labelPage == 'Edit' && access && formData.status != 'accepted' && formData.status != 'reject pic' && formData.status != 'reject finance'"
            >
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div v-show="validated != 1 || labelPage == 'Tambah'">
              <button class="btn btn-save mr-2" style="background: #ffa736; border-color: #ffa736" type="button" @click="accReimburse" :disabled="isSubmit" v-if="formData.status == 'waiting confirmation pic' || formData.status == 'waiting confirmation finance'">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Setujui
              </button>
              <button class="btn btn-save mr-2" style="background: #ff5252; border-color: #ff5252" type="button" @click="tolakReimburse" :disabled="isSubmit" v-if="formData.status == 'waiting confirmation pic' || formData.status == 'waiting confirmation finance'">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Tolak
              </button>
              <button class="btn btn-save" :disabled="isSubmit" v-if="formData.status != 'waiting confirmation pic' && formData.status != 'waiting confirmation finance'">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card info-detail">
            <div class="row">
              <div class="col-7">
                <div class="title">Detail Reimburse</div>
                <div class="sub-title">
                  {{ labelPage }} informasi yang diperlukan
                </div>
              </div>
              <div class="col-5 d-flex justify-content-end align-items-center" v-if="formData.status != 'accepted'">
                <div class="green-bedge" v-if="formData.status == 'accepted'">
                      Accepted
                    </div>
                    <div class="orange-bedge text-capitalize" v-else-if="formData.status == 'waiting confirmation pic' || formData.status == 'waiting confirmation finance'">
                      {{ formData.status }}
                    </div>
                    <div
                      class="red-bedge text-capitalize"
                      v-else
                    >
                      {{ formData.status }}
                    </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group" v-if="labelPage === 'Edit'">
                  <label>Nomor :</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Masukkan Nomor"
                    disabled="true"
                    v-model="formData.no_transaksi"
                  />
                </div>

                <div class="form-group">
                  <label>Karyawan :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.id_karyawan"
                    :options="optionKaryawan"
                    placeholder="Pilih Karyawan"
                    @change="formChange('id_karyawan')"
                    @select="formChange('id_karyawan')"
                    :class="{
                      'is-invalid': formError && formError.id_karyawan,
                    }"
                  />
                  <div class="form-error">
                    {{ formError.id_karyawan }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Project :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.kode_project"
                    :options="optionProject"
                    placeholder="Pilih Project"
                    @change="formChange('kode_project', $event)"
                    @select="formChange('kode_project', $event)"
                    :class="{
                      'is-invalid': formError && formError.kode_project,
                    }"
                  />
                  <div class="form-error">
                    {{ formError.kode_project }}
                  </div>
                </div>
                
                <div class="form-group">
                  <label>Departemen :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.id_divisi"
                    :options="optionDepartement"
                    placeholder="Pilih Departemen"
                    @change="formChange('id_divisi')"
                    @select="formChange('id_divisi')"
                    :class="{
                      'is-invalid': formError && formError.id_divisi,
                    }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_divisi"
                  >
                    {{ formError.id_divisi }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Item RAB :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.item_rab"
                    :options="optionRAB"
                    placeholder="Pilih Item RAB"
                    :settings="{
                      templateResult: formatState,
                    }"
                    @change="formChange('item_rab', $event)"
                    @select="formChange('item_rab', $event)"
                    :class="{ 'is-invalid': formError && formError.item_rab }"
                  />
                  <div class="form-error">
                    {{ formError.item_rab }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Kas & Bank :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.akun_bank"
                    :options="optionKasBank"
                    placeholder="Pilih Kas & Bank"
                    :settings="{ templateResult: formatState }"
                    @change="formChange('akun_bank')"
                    @select="formChange('akun_bank')"
                    :class="{ 'is-invalid': formError && formError.akun_bank }"
                  />
                  <div class="form-error">
                    {{ formError.akun_bank }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Akun Beban :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.beban_akun"
                    :options="optionExpenseAccount"
                    placeholder="Pilih Akun Beban"
                    :settings="{ templateResult: formatState }"
                    @change="formChange('beban_akun')"
                    @select="formChange('beban_akun')"
                    :class="{ 'is-invalid': formError && formError.beban_akun }"
                  />
                  <div class="form-error">
                    {{ formError.beban_akun }}
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>Tanggal Pengajuan :</label>
                  <Datepicker
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formData.tanggal_pengajuan"
                    :maxDate="new Date()"
                    placeholder="Tanggal"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    :class="{
                      'is-invalid': formError && formError.tanggal_pengajuan,
                    }"
                    @update:modelValue="changeDate($event, 'tanggal_pengajuan')"
                  ></Datepicker>
                  <div
                    class="form-error"
                    v-if="formError && formError.tanggal_pengajuan"
                  >
                    {{ formError.tanggal_pengajuan }}
                  </div>
                </div>

                <div class="form-group">
                  <label class="">Nominal :</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Rp</div>
                    </div>
                    <input
                      :disabled="validated == 1 && labelPage == 'Edit'"
                      type="text"
                      class="form-control text-right"
                      id="inlineFormInputGroupUsername"
                      placeholder="Masukan Nilai Kasbon"
                      v-model.lazy="formData.nominal"
                      v-money="configMoney"
                      @keyup="formChange('nominal')"
                      :class="{ 'is-invalid': formError && formError.nominal }"
                    />
                  </div>
                  <div class="form-error" v-if="formError && formError.nominal">
                    {{ formError.nominal }}
                  </div>
                </div>
                <div class="form-group">
                  <label>Keterangan</label>
                  <textarea
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    style="height: 140px !important"
                    v-model="formData.keterangan"
                  ></textarea>
                </div>

                <div class="form-group">
                  <label>File</label>
                  <fileUpload
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    :file="listFile"
                    @update="setFile"
                    @delete="setDelete"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";
// import UploadImages from "vue-upload-drop-images";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import fileUpload from "../components/fileUpload.vue";

import $ from "jquery";
import { get_AkunList, get_AkunDefault } from "../../../actions/akun_perkiraan";
import {
  cksClient,
  checkRules,
  showAlert,
  checkModuleAccess,
  checkFileOrImageFromLink,
} from "../../../helper";
import { get_KaryawanList } from "../../../actions/karyawan";
import moment from "moment";
import "moment/locale/id";
import { get_ProjectList } from "../../../actions/project";
import { get_ProjectRabList } from "../../../actions/project/rab";
import { VMoney } from "v-money";
import {
  get_ReimburseDetail,
  post_ReimburseSave,
} from "../../../actions/reimburse";
import { DATA } from "../../../constans/Url";
import { get_ListDivisi } from "../../../actions/master";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // UploadImages,
    fileUpload,
  },

  directives: {
    money: VMoney,
  },

  data() {
    return {
      access: true,
      checkAccess: checkModuleAccess,
      validated: 1,
      file: [],
      nomor: "",
      employeeName: "",
      project: "",
      rabItem: "",
      kasBank: "",
      expenseAccount: "",
      dateFiling: "",
      nominal: "",
      description: "",
      optionProject: [],
      optionRAB: [],
      optionKaryawan: [],
      optionKasBank: [],
      optionExpenseAccount: [],
      optionDepartement: [],
      id_company: cksClient().get("_account").id_company,
      labelPage: this.$route.params.id ? "Edit" : "Tambah",

      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      //FORM
      isSubmit: false,
      listFile: [],
      fileDelete: [],
      formData: {
        no_transaksi: this.$route.params.id ? this.$route.params.id : "",
        id_company: cksClient().get("_account").id_company,
        id_karyawan: "",
        id_divisi: "",
        kode_project: "",
        item_rab: "",
        akun_bank: "",
        beban_akun: "",
        tanggal_pengajuan: "",
        nominal: "",
        tanggal_acc_pic: "",
        tanggal_acc_finance: "",
        keterangan: "",
        no_jurnal: "",
        status: "accepted",
      },
      formError: [],
      rules: {
        no_transaksi: {
          required: false,
        },
        id_karyawan: {
          required: false,
        },
        kode_project: {
          required: false,
        },
        item_rab: {
          required: false,
        },
        akun_bank: {
          required: true,
        },
        beban_akun: {
          required: true,
        },
        tanggal_pengajuan: {
          required: true,
        },
        nominal: {
          required: true,
        },
        tanggal_acc_pic: {
          required: false,
        },
        tanggal_acc_finance: {
          required: false,
        },
        keterangan: {
          required: false,
        },
        no_jurnal: {
          required: false,
        },
      },
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  created() {
    this.getAkun("1");
    this.getAkun("1", true);
    this.getKaryawan();
    this.getProject();
    this.getDivisi();
    if (this.formData.no_transaksi) {
      this.getData();
    } else {
      this.getDefaultAkun();
    }

    if (!this.checkAccess("reimburse", "cru_reimburse")) {
      this.access = false;
    }
  },

  methods: {
    editable() {
      this.validated = 2;
    },
    getDefaultAkun() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      var name_id = "akun_reimburse_bank,akun_reimburse_beban";
      get_AkunDefault(
        {
          id_company: this.id_company,
          name_id: name_id,
          id_divisi: id_divisi,
        },
        (res) => {
          var list = res.list;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              if (key == 0) {
                this.formData.akun_bank = element
              } else if (key == 1) {
                this.formData.beban_akun = element;
              }
            }
          }
        }
      );
    },
    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.optionDepartement = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.optionDepartement.push(element);
          }
        }
      });
    },
    getData() {
      get_ReimburseDetail(
        this.formData.no_transaksi,
        (res) => {
          var { data } = res;
          for (const key in data.files) {
            if (Object.hasOwnProperty.call(data.files, key)) {
              const element = data.files[key];
              var type = checkFileOrImageFromLink(element.nama_file);
              this.listFile.push({
                id: element.session,
                preview: type == 'file' ? '/img/icon_documents.png' : DATA.REIMBURSE(element.nama_file),
                link_preview: DATA.REIMBURSE(element.nama_file),
                file: null,
                type: checkFileOrImageFromLink(element.nama_file)
              });
            }
          }
          delete data.files;
          delete data.created_at;
          delete data.updated_at;
          data.tanggal_pengajuan = moment(data.tanggal_pengajuan).format(
            "YYYY-MM-DD"
          );
          data.tanggal_acc_finance = data.tanggal_acc_finance
            ? moment(data.tanggal_acc_finance).format("YYYY-MM-DD HH:mm:ss")
            : null;

          data.tanggal_acc_pic = data.tanggal_acc_pic
            ? moment(data.tanggal_acc_pic).format("YYYY-MM-DD HH:mm:ss")
            : null;

          this.formData = data;
          if (data.kode_project) {
            this.getItem();
          }
          if (!data.beban_akun){
            this.getDefaultAkun();
          }
        },
        () => {
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Data tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataReimburse",
              });
            },
          });
        }
      );
    },
    //MASTER
    getKaryawan() {
      get_KaryawanList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.optionKaryawan = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionKaryawan.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },
    getProject() {
      get_ProjectList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.optionProject = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionProject.push({
                id: element.kode,
                text: element.nama,
                id_divisi: element.id_divisi
              });
            }
          }
        }
      );
    },
    getItem() {
      get_ProjectRabList(this.formData.kode_project, (res) => {
        const { list } = res;
        this.optionRAB = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.optionRAB.push({
              id: element.id,
              text: element.nama,
              sub_level: element.sub_level,
              disabled: element.hasChild === 1 ? true : false,
              nilai: element.total_rab,
              max: element.sisa_nilai,
            });
          }
        }
      });
    },
    getAkun(tipe = "", not = false) {
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: tipe,
          not_type: not,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          if (tipe == "1" && !not) {
            this.optionKasBank = data;
          } else {
            this.optionExpenseAccount = data;
          }
        }
      );
    },
    changeDate(data, key) {
      this.formData[key] = moment(data).format("YYYY-MM-DD");
      this.formChange(key);
    },
    async formChange(key, val) {
      if (Object.hasOwnProperty.call(this.rules, key)) {
        this.rules[key].changed = true;
      }
      if (key == 'item_rab' && val.id != "") {
        this.rules.nominal.max = val.max
      } else if (key == 'item_rab') {
        delete this.rules.nominal.max
      }
      console.log(this.rules.nominal)
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (key == "kode_project") {
        this.formData.id_divisi = val.id_divisi
        this.getItem();
      }
    },
    //MASTER
    setFile(e) {
      this.listFile = e;
    },
    setDelete(e) {
      if (e) {
        this.fileDelete.push(e);
      }
    },
    showModal() {
      $("#modalAddPayment").modal("show");
    },
    createInvoice() {
      location.href = "/data-credit/tambah-invoice";
    },
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = "";
      if (state.type && state.no) {
        $state = $(
          '<div class="' +
            padding +
            '">' +
            state.text +
            '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
            state.no +
            "<div>" +
            state.type +
            "</div></div></div>"
        );
      } else {
        $state = $('<div class="' + padding + '">' + state.text + "</div>");
      }
      return $state;
    },

    postData(status = '') {
      var dataForm = new FormData();
      for (const key in this.formData) {
        if (Object.hasOwnProperty.call(this.formData, key)) {
          if (status && key == "status") {
            // const el = this.formData[key];
            dataForm.append(key, status);
          } else {
            const element = this.formData[key];
            dataForm.append(key, element);
          }
        }
      }
      // this.formData.files = [];
      for (const key in this.listFile) {
        if (Object.hasOwnProperty.call(this.listFile, key)) {
          const element = this.listFile[key];
          if (element.file) {
            dataForm.append(`files[]`, element.file);
            // this.formData.files.push(element.file);
          }
        }
      }
      for (const key in this.fileDelete) {
        if (Object.hasOwnProperty.call(this.fileDelete, key)) {
          const element = this.fileDelete[key];
          dataForm.append(`deletefile[]`, element);
        }
      }
      // console.log(this.listFile, this.formData);

      this.isSubmit = true;
      post_ReimburseSave(
        dataForm,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code == 201
              ? "Data reimburse berhasil dicatat"
              : "Data reimburse berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({
                name: "DataReimburse",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }

      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.no_transaksi
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
    async accReimburse() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }

      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin akan menyetujui data ini, data tidak bisa diubah kembali?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData('accepted');
          },
        });
      }
    },
    async tolakReimburse() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }

      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin akan menolak data ini, data tidak bisa diubah kembali?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData('reject finance');
          },
        });
      }
    }
  },
  
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

svg {
  width: 50px !important;
}

.form-label {
  max-width: 134px;
  width: 200px;
}

.container {
  min-height: 140px !important ;
  background: transparent !important;
  border: 1px solid #eceff1 !important;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
</style>
