import { API } from "../../constans/Url"
import { apiClient } from "../../helper"

const get_ReimburseList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REIMBURSE.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


const post_ReimburseSave = (params, callbackSuccess, callbackError) => {
  // {
  //   headers: {
  //       'Content-Type': 'multipart/form-data'
  //   }
  // }
  apiClient(true).post(API.REIMBURSE.SAVE, params,
  {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  }).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ReimburseDetail = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REIMBURSE.GET, {no_transaksi: id}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_ReimburseList,
  post_ReimburseSave,
  get_ReimburseDetail,
}